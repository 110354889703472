import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { StorageService } from '../services';
import { AppConstants } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {

  constructor(private storageService: StorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    debugger;
    if (request.url.includes(`token`)) return next.handle(request); // checks the login path
    
    if (request.url.includes(`User/FlushSession`)) {
      return throwError({ status: 401, error: { message: 'flusedSession' } });
    }  

    let clonedRequest = null;
    let headers = request.headers.set('Authorization', `Bearer ${this.storageService.GetToken()}`);
    clonedRequest = request.clone({headers});

    return next.handle(clonedRequest);
  }
}
