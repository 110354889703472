import { BaseResponse } from './../models/responses/base.response';
import { Injectable } from '@angular/core';
// Angular
import { HttpClient, HttpHeaders } from '../common/angular.index';
// Models
import {
  AppConstants,
  Contact,
  ContactsResponse,
} from '../common/models.index';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  getContacts() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });
    return this.http.get<ContactsResponse>(
      `${AppConstants.API_URL}api/Contacts/GetContacts`,
      { headers }
    );
  }

  handlePostOrPutContact(contact: Contact) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });

    if (contact.Id === 0)
      return this.http.post<BaseResponse>(
        `${AppConstants.API_URL}api/Contacts/PostContact`,
        contact,
        { headers }
      );
    else
      return this.http.put<BaseResponse>(
        `${AppConstants.API_URL}api/Contacts/PutContact`,
        contact,
        { headers }
      );
  }

  deleteContact(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });

    return this.http.delete<BaseResponse>(
      `${AppConstants.API_URL}api/Contacts/DeleteContact?id=${id}`,
      { headers }
    );
  }
}
