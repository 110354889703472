import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Form, FormGroup } from '@angular/forms';

// MODELOS
import {
  ManualSendCompanyInfo, ManualSendReportBPResponse, ManualSendReportCompanyResponse, ManualSendBpInfo, ManualSendSupplierInfo
} from 'src/app/models'
import { AppConstants} from 'src/app/common/constants'
// RUTAS
// COMPONENTES
// SERVICIOS
import {StorageService} from 'src/app/services/storage.service'
import { Observable } from 'src/app/common/angular.index';

@Injectable({
  providedIn: 'root'
})
export class ManualSendService {

  constructor(private http: HttpClient,
    private storageService: StorageService) { }

  // reenvio reporte bp por correo modo manual
  // sendManualReportBP(searchBusinessPartnerForm: FormGroup, BusinessPartnerFieldForm: FormGroup) {
  //   const TOKEN = this.storageService.getToken(); 
  //   console.log("sendManualReportBP");
  //   const CardCode = BusinessPartnerFieldForm.controls['CardCode'].value
  //   let ModCardCode = CardCode.split(' ');
  //   //let currentUser = JSON.parse(this.storageService.getStorage('currentUser'));    
  //   let currentUser = JSON.parse(this.storageService.GetCurrentSession());    
  //   const ManualSendBpInfo: ManualSendBpInfo = {      
  //     CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
  //     CardCode: ModCardCode[0],
  //     User: currentUser.UserName
  //   }
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${TOKEN.access_token}`
  //   });
  //   return this.http.post<ManualSendReportBPResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/Documents/SendManualBPReport`,
  //   ManualSendBpInfo,
  //     { headers }
  //   );
  // }

  sendManualReportBP(searchBusinessPartnerForm: FormGroup, BusinessPartnerFieldForm: FormGroup): Observable<ManualSendReportBPResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${token.access_token}`
    });

    const CardCode = BusinessPartnerFieldForm.controls['CardCode'].value
    let ModCardCode = CardCode.split(' ');
    //let currentUser = JSON.parse(this.storageService.getStorage('currentUser'));    
    let currentUser = JSON.parse(this.storageService.GetCurrentSession());    
    const ManualSendBpInfo: ManualSendBpInfo = {      
      CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
      CardCode: ModCardCode[0],
      User: currentUser.UserName
    }

    const url = `${AppConstants.API_URL}api/Documents/SendManualBPReport`;
    
    return this.http.post<ManualSendReportBPResponse>(url, ManualSendBpInfo, { headers });
  }

  // // reenvio reporte compania por correo modo manual
  // sendManualCompanyReport(searchBusinessPartnerForm: FormGroup) {
  //   const TOKEN = this.storageService.getToken();  
  //   console.log("sendManualCompanyReport");
  //   //let currentUser = JSON.parse(this.storageService.getStorage('currentUser'));
  //   let currentUser = JSON.parse(this.storageService.GetCurrentSession());
  //   const ManualSendCompanyInfo: ManualSendCompanyInfo = {      
  //     CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
  //     User: currentUser.UserName
  //   }
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${TOKEN.access_token}`
  //   });
  //   return this.http.post<ManualSendReportCompanyResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/Documents/SendManualCompanyReport`,
  //   ManualSendCompanyInfo,
  //     { headers }
  //   );
  // }

  sendManualCompanyReport(searchBusinessPartnerForm: FormGroup): Observable<ManualSendReportCompanyResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${token.access_token}`
    });

    let currentUser = JSON.parse(this.storageService.GetCurrentSession());
    const ManualSendCompanyInfo: ManualSendCompanyInfo = {      
      CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
      User: currentUser.UserName
    }

    const url = `${AppConstants.API_URL}api/Documents/SendManualCompanyReport`;
    
    return this.http.post<ManualSendReportCompanyResponse>(url, ManualSendCompanyInfo, { headers });
  }

  //#region PROVEEDOR
  // // reenvio reporte bp por correo modo manual
  // sendManualReportSupplier(searchSupplierForm: FormGroup, BusinessPartnerFieldFormSenderReport: FormGroup) {
  //   const TOKEN = this.storageService.getToken(); 
  //   console.log("sendManualReportSupplier");
  //   const CardCode = BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].value
  //   let ModCardCode = CardCode.split(' ');
  //   //let currentUser = JSON.parse(this.storageService.getStorage('currentUser'));    
  //   let currentUser = JSON.parse(this.storageService.GetCurrentSession());
  //   const ManualSendBpInfo: ManualSendSupplierInfo = {      
  //     CompanyId: searchSupplierForm.controls['companyId'].value,
  //     CardCode: ModCardCode[0],
  //     User: currentUser.UserName,
  //     SendType: searchSupplierForm.controls['sendType'].value,
  //     StartDate: searchSupplierForm.controls['startDate'].value,
  //     EndDate: searchSupplierForm.controls['endDate'].value,
  //   }
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${TOKEN.access_token}`
  //   });
  //   return this.http.post<ManualSendReportBPResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/Documents/SendManualSupplierReport`,
  //   ManualSendBpInfo,
  //     { headers }
  //   );
  // }

  sendManualReportSupplier(searchSupplierForm: FormGroup, BusinessPartnerFieldFormSenderReport: FormGroup): Observable<ManualSendReportBPResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${token.access_token}`
    });

    const CardCode = BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].value
    let ModCardCode = CardCode.split(' ');
    //let currentUser = JSON.parse(this.storageService.getStorage('currentUser'));    
    let currentUser = JSON.parse(this.storageService.GetCurrentSession());
    const ManualSendBpInfo: ManualSendSupplierInfo = {      
      CompanyId: searchSupplierForm.controls['companyId'].value,
      CardCode: ModCardCode[0],
      User: currentUser.UserName,
      SendType: searchSupplierForm.controls['sendType'].value,
      StartDate: searchSupplierForm.controls['startDate'].value,
      EndDate: searchSupplierForm.controls['endDate'].value,
    }

    const url = `${AppConstants.API_URL}api/Documents/SendManualSupplierReport`;
    
    return this.http.post<ManualSendReportBPResponse>(url, ManualSendBpInfo, { headers });
  }
  //#endregion PROVEEDOR
}
