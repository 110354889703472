import { Injectable } from '@angular/core';

// Angular common
import { HttpClient, HttpHeaders } from '../common/angular.index';

// Models
import {
  AppConstants,
  DocumentSearch,
  DocumentResponse,
  DocumentHistoricResponse,
} from '../common/models.index';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  getDocuments(documentSearch: DocumentSearch) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });
    return this.http.post<DocumentResponse>(
      `${AppConstants.API_URL}api/Document/GetDocuments`,
      documentSearch,
      { headers }
    );
  }

  getDocumentHistoric(documentId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });
    return this.http.get<DocumentHistoricResponse>(
      `${AppConstants.API_URL}api/Document/GetHistoric?documentId=${documentId}`,
      { headers }
    );
  }
}
