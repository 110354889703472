import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbTypeahead, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ALERTS } from 'src/app/common/models.index';

// MODELOS
import { SearchCompanyLogModel, CompanyInfoLog, CompanyInfoLogResponse,
  ManualSendReportCompanyResponse, GetPdfReportResponse, CompaniesResponse, Company } from 'src/app/models'

// RUTAS
// COMPONENTES
// SERVICIOS
import {AlertService, SearchService, ManualSendService, ConfigParamsService} from 'src/app/services'

@Component({
  selector: 'app-companylog',
  templateUrl: './companylog.component.html',
  styleUrls: ['./companylog.component.css']
})
export class CompanylogsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  public searchCompanyForm: FormGroup;
  
  infoSearchCompanyReport: SearchCompanyLogModel; // obj almacena la info para busqueda reporte por proveedor
  CompanyInfoLog: Array<CompanyInfoLog> = []; // obj almacena la info de logs consultada
  Today: any;
  date: Date = new Date(); // fecha actual
  closeResult = '';
  
  statusLog: {key: string, value: string}[] = [
    {key: '0', value: 'Todos'},
    {key: '1', value: 'Pendiente'},
    {key: '2', value: 'Enviando'},
    {key: '3', value: 'Enviado'},
    {key: '4', value: 'Error'}
  ];

  errorMessage: string; // variable para el mensaje en caso de error
  companyList: Array<Company>; // almacena lista de compañias que se utiliza para cargar dropdown
  
  constructor(private fb: FormBuilder,
    private searchService: SearchService,
    private alertService: AlertService,
    private ManualSendService: ManualSendService,
    private configParamsService: ConfigParamsService,
    private modalService: NgbModal
    ) { }

  ngOnInit() {    
    this.ngLoad();
  }
  ngLoad() {
    this.getCompaniesList();
    this.Today = `${this.date.getFullYear()}-${('0' + (this.date.getMonth() + 1)).slice(-2)}-${('0' + this.date.getDate()).slice(-2)}`;
    this.searchCompanyForm = this.fb.group({
      startDate: [this.Today, Validators.required],
      endDate: [this.Today, Validators.required],
      companyId: ['', Validators.required],
      CardCode: [''],
      Status: [this.statusLog[0].key, Validators.required]
    });
  }

  get companyIdForm() {
    return this.searchCompanyForm.get('companyId');
  }

  getCompaniesList() {
    this.blockUI.start('Buscando compañías');
    console.log('Buscando compañías');
    this.configParamsService.getCompanyList()
      .subscribe((data: CompaniesResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.companyList = data.CompanyList;
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  getCompanyLogs(){
    this.CompanyInfoLog = [];
    console.log('Obteniendo logs de proveedores');
    this.blockUI.start('Obteniendo reportes de proveedores.');    
    this.searchService.getCompanyLogs(this.searchCompanyForm)
      .subscribe((data: CompanyInfoLogResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.CompanyInfoLog = data.CompanyInfoLogList;
          this.blockUI.stop();          
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  sendManualCompanyReport(){
    this.blockUI.start('Enviando reporte por compañía');
    console.log('Enviando reporte por compañía');
    this.ManualSendService.sendManualCompanyReport(this.searchCompanyForm)
      .subscribe((data: ManualSendReportCompanyResponse) => {
        if (data.Result) {
          this.alertService.ShowBigCenteredAlert(`Reporte enviado`, ALERTS.success);
          this.blockUI.stop();

        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  // funcion para la visualizacion del archivo
  // recibe como parametro el id del documento a visualizar
  viewDocument() {
    this.blockUI.start('Generando reporte'); // Start blocking
    this.searchService.GetPdfReportCompany(this.searchCompanyForm)
      .subscribe((data: GetPdfReportResponse) => {
        if(data.Result){
          this.blockUI.stop(); // Stop blocking
          console.log(data);
          var fileBase64 = atob(data.PdfBase64);
          var length = fileBase64.length;
          var arrayBuffer = new ArrayBuffer(length);
          var uintArray = new Uint8Array(arrayBuffer);
          for (var i = 0; i < length; i++) {
            uintArray[i] = fileBase64.charCodeAt(i);
          }
          var tab = window.open();
          var pdfFile = new Blob([uintArray], { type: 'application/pdf' });
          var fileUrl = URL.createObjectURL(pdfFile);
          tab.location.href = fileUrl;
        }
        else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
      }, (error: any) => {
        this.blockUI.stop(); // Stop blocking
        console.log('error');
        console.log(error);
        this.alertService.ShowBigCenteredAlert(`${error.Message}`, ALERTS.error); 
      });
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
