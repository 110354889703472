import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, XhrFactory } from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

// MODELOS
import {
  SearchCompanyLogModel, CompanyInfoLogResponse, BPInfoLogResponse, 
  BusinessPartnerInfoResponse, GetPdfReportResponse, SearchBusinessPartnerLogModel, GetBpFromSap,
  PdfInfo
} from 'src/app/models'
import { AppConstants, sendTypes, ReportType, ReportSubType} from 'src/app/common/constants'
import { FormGroup } from '@angular/forms';
// RUTAS
// COMPONENTES
// SERVICIOS
import { StorageService } from 'src/app/services/storage.service'
import { Observable } from 'src/app/common/angular.index';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  @BlockUI() blockUI: NgBlockUI;

  constructor(private http: HttpClient,
    private storageService: StorageService
    // private alertService: AlertService) {
  ) {
    console.log('SearchService listo');
  }

  // // obtiene los reportes de proveedores
  // getBusinessPartnerLogs(searchBusinessPartnerForm: FormGroup) {
  //   console.log('Obteniendo logs de compañias');
  //   const TOKEN = this.storageService.getToken();
  //   const itemCode = String(searchBusinessPartnerForm.controls['CardCode'].value);
  //   const itemCodeSAP = itemCode.split(' ');
  //   const SearchBPInfoLog: SearchBusinessPartnerLogModel = {
  //     StartDate: searchBusinessPartnerForm.controls['startDate'].value,
  //     EndDate: searchBusinessPartnerForm.controls['endDate'].value,
  //     CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
  //     CardCode: itemCodeSAP[0],
  //     Status: searchBusinessPartnerForm.controls['Status'].value
  //   }

  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `bearer ${TOKEN}`
  //   });
  //   return this.http.post<BPInfoLogResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/Documents/GetBusinessPartnerLog`,
  //     SearchBPInfoLog,
  //     { headers }
  //   );
  // }

  getBusinessPartnerLogs(searchBusinessPartnerForm: FormGroup): Observable<BPInfoLogResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${token.access_token}`
    });

    const itemCode = String(searchBusinessPartnerForm.controls['CardCode'].value);
    const itemCodeSAP = itemCode.split(' ');
    const SearchBPInfoLog: SearchBusinessPartnerLogModel = {
      StartDate: searchBusinessPartnerForm.controls['startDate'].value,
      EndDate: searchBusinessPartnerForm.controls['endDate'].value,
      CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
      CardCode: itemCodeSAP[0],
      Status: searchBusinessPartnerForm.controls['Status'].value
    }

    const url = `${AppConstants.API_URL}api/Documents/GetBusinessPartnerLog`;
    
    return this.http.post<BPInfoLogResponse>(url, SearchBPInfoLog, { headers });
  }

  // // obtiene los proveedores de SAP
  // getBusinessPartnerFromSap(searchBusinessPartnerForm: FormGroup, typeBp: string) {
  //   console.log('Obteniendo proveedores de compañias');
  //   const TOKEN = this.storageService.getToken();
  //   const SearchBPFromSap: GetBpFromSap = {
  //     CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
  //     Type: typeBp
  //   }
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `bearer ${TOKEN}`
  //   });
  //   return this.http.post<BusinessPartnerInfoResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/BusinessPartner/GetBusinessPartnerFromSap`,
  //     SearchBPFromSap,
  //     { headers }
  //   );
  // }

  getBusinessPartnerFromSap(searchBusinessPartnerForm: FormGroup, typeBp: string): Observable<BusinessPartnerInfoResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${token.access_token}`
    });

    const SearchBPFromSap: GetBpFromSap = {
      CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
      Type: typeBp
    }

    const url = `${AppConstants.API_URL}api/BusinessPartner/GetBusinessPartnerFromSap`;
    
    return this.http.post<BusinessPartnerInfoResponse>(url, SearchBPFromSap, { headers });
  }

  // // obtiene los reportes de proveedores
  // getCompanyLogs(searchCompanyForm: FormGroup) {
  //   console.log('Obteniendo logs de proveedores');
  //   const TOKEN = this.storageService.getToken();

  //   const SearchCompanyInfoLog: SearchCompanyLogModel = {
  //     StartDate: searchCompanyForm.controls['startDate'].value,
  //     EndDate: searchCompanyForm.controls['endDate'].value,
  //     CompanyId: searchCompanyForm.controls['companyId'].value,
  //     Status: searchCompanyForm.controls['Status'].value
  //   }
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `bearer ${TOKEN}`
  //   });
  //   return this.http.post<CompanyInfoLogResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/Documents/GetCompanyLog`,
  //     SearchCompanyInfoLog,
  //     { headers }
  //   );
  // }

  getCompanyLogs(searchCompanyForm: FormGroup): Observable<CompanyInfoLogResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${token.access_token}`
    });

    const SearchCompanyInfoLog: SearchCompanyLogModel = {
      StartDate: searchCompanyForm.controls['startDate'].value,
      EndDate: searchCompanyForm.controls['endDate'].value,
      CompanyId: searchCompanyForm.controls['companyId'].value,
      Status: searchCompanyForm.controls['Status'].value
    }

    const url = `${AppConstants.API_URL}api/Documents/GetCompanyLog`;
    
    return this.http.post<CompanyInfoLogResponse>(url, SearchCompanyInfoLog, { headers });
  }

  // // obtiene reporte de cliente para visualizarlo en pantalla
  // GetPdfReportBP(searchBusinessPartnerForm: FormGroup, BusinessPartnerFieldForm: FormGroup) {
  //   console.log('Obteniendo reporte por cliente');
  //   const TOKEN = this.storageService.getToken();
  //   const itemCode = String(BusinessPartnerFieldForm.controls['CardCode'].value);
  //   const itemCodeSAP = itemCode.split(' ');
  //   const PdfInfo: PdfInfo = {
  //     CardCode: itemCodeSAP[0],
  //     CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
  //     ReportType: ReportType.BusinessPartner,
  //     ReportSubType: ReportSubType.BusinessPartner
  //   }

  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'responseType': 'blob',
  //     'Authorization': `bearer ${TOKEN}`
  //   });
  //   return this.http.post<GetPdfReportResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/Documents/GetPdfReport`,
  //     PdfInfo,
  //     { headers }
  //   );
  // }

  GetPdfReportBP(searchBusinessPartnerForm: FormGroup, BusinessPartnerFieldForm: FormGroup): Observable<GetPdfReportResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'responseType': 'blob'
    });

    const itemCode = String(BusinessPartnerFieldForm.controls['CardCode'].value);
    const itemCodeSAP = itemCode.split(' ');
    const PdfInfo: PdfInfo = {
      CardCode: itemCodeSAP[0],
      CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
      ReportType: ReportType.BusinessPartner,
      ReportSubType: ReportSubType.BusinessPartner
     
    }

    const url = `${AppConstants.API_URL}api/Documents/GetPdfReport`;
    
    return this.http.post<GetPdfReportResponse>(url, PdfInfo, { headers });
  }

  // // obtiene reporte de cliente para visualizarlo en pantalla
  // GetPdfReportSupplier(searchBusinessPartnerForm: FormGroup, BusinessPartnerFieldFormSenderReport: FormGroup) {
  //   console.log('Obteniendo reporte por cliente');
  //   const TOKEN = this.storageService.getToken();
  //   let itemCode = String(BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].value);
  //   let itemCodeSAP = itemCode.split(' ');
  //   const PdfInfo: PdfInfo = {
  //     CardCode: itemCodeSAP[0],
  //     CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
  //     ReportType: ReportType.BusinessPartner,
  //     ReportSubType: ReportSubType.BusinessPartnerSupplier,
  //     StartDate: searchBusinessPartnerForm.controls['startDate'].value,
  //     EndDate: searchBusinessPartnerForm.controls['endDate'].value,
  //   }

  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'responseType': 'blob',
  //     'Authorization': `bearer ${TOKEN}`
  //   });
  //   return this.http.post<GetPdfReportResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/Documents/GetPdfReport`,
  //     PdfInfo,
  //     { headers }
  //   );
  // }

  GetPdfReportSupplier(searchBusinessPartnerForm: FormGroup, BusinessPartnerFieldFormSenderReport: FormGroup): Observable<GetPdfReportResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'responseType': 'blob'
    });

    let itemCode = String(BusinessPartnerFieldFormSenderReport.controls['CardCodeSenderReport'].value);
    let itemCodeSAP = itemCode.split(' ');
    const PdfInfo: PdfInfo = {
      CardCode: itemCodeSAP[0],
      CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
      ReportType: ReportType.BusinessPartner,
      ReportSubType: ReportSubType.BusinessPartnerSupplier,
      StartDate: searchBusinessPartnerForm.controls['startDate'].value,
      EndDate: searchBusinessPartnerForm.controls['endDate'].value,
    }

    const url = `${AppConstants.API_URL}api/Documents/GetPdfReport`;
    
    return this.http.post<GetPdfReportResponse>(url, PdfInfo, { headers });
  }

  // // obtiene reporte de cliente para visualizarlo en pantalla
  // GetPdfReportCompany(searchCompanyForm: FormGroup) {
  //   console.log('Obteniendo reporte por compañía');
  //   const TOKEN = this.storageService.getToken();
  //   const PdfInfo: PdfInfo = {
  //     CardCode: "",
  //     CompanyId: searchCompanyForm.controls['companyId'].value,
  //     ReportType: ReportType.Company,
  //     ReportSubType: ReportSubType.Company
  //   }

  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'responseType': 'blob',
  //     'Authorization': `bearer ${TOKEN}`
  //   });
  //   return this.http.post<GetPdfReportResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/Documents/GetPdfReport`,
  //     PdfInfo,
  //     { headers }
  //   );
  // }

  GetPdfReportCompany(searchCompanyForm: FormGroup): Observable<GetPdfReportResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'responseType': 'blob'
    });

    const PdfInfo: PdfInfo = {
      CardCode: "",
      CompanyId: searchCompanyForm.controls['companyId'].value,
      ReportType: ReportType.Company,
      ReportSubType: ReportSubType.Company
    }

    const url = `${AppConstants.API_URL}api/Documents/GetPdfReport`;
    
    return this.http.post<GetPdfReportResponse>(url, PdfInfo, { headers });
  }  

  //#region PROVEEDORES
  // // obtiene los reportes de proveedores
  // getSuppliersLogs(searchBusinessPartnerForm: FormGroup) {
  //   console.log('Obteniendo logs de compañias');
  //   const TOKEN = this.storageService.getToken();
  //   const itemCode = String(searchBusinessPartnerForm.controls['CardCode'].value);
  //   const itemCodeSAP = itemCode.split(' ');
  //   const SearchBPInfoLog: SearchBusinessPartnerLogModel = {
  //     StartDate: searchBusinessPartnerForm.controls['startDate'].value,
  //     EndDate: searchBusinessPartnerForm.controls['endDate'].value,
  //     CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
  //     CardCode: itemCodeSAP[0],
  //     Status: searchBusinessPartnerForm.controls['Status'].value
  //   }

  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `bearer ${TOKEN}`
  //   });
  //   return this.http.post<BPInfoLogResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/Documents/GetSuppliersLog`,
  //     SearchBPInfoLog,
  //     { headers }
  //   );
  // }

  getSuppliersLogs(searchBusinessPartnerForm: FormGroup): Observable<BPInfoLogResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'responseType': 'blob'
    });

    const itemCode = String(searchBusinessPartnerForm.controls['CardCode'].value);
    const itemCodeSAP = itemCode.split(' ');
    const SearchBPInfoLog: SearchBusinessPartnerLogModel = {
      StartDate: searchBusinessPartnerForm.controls['startDate'].value,
      EndDate: searchBusinessPartnerForm.controls['endDate'].value,
      CompanyId: searchBusinessPartnerForm.controls['companyId'].value,
      CardCode: itemCodeSAP[0],
      Status: searchBusinessPartnerForm.controls['Status'].value
    }

    const url = `${AppConstants.API_URL}api/Documents/GetSuppliersLog`;
    
    return this.http.post<BPInfoLogResponse>(url, SearchBPInfoLog, { headers });
  }

  // // obtiene los proveedores de SAP
  // getSuppliersFromSap(searchSupplierForm: FormGroup, typeBp: string, sendType: string) {
  //   console.log('Obteniendo proveedores de compañias');
  //   const TOKEN = this.storageService.getToken();
  //   let StartDate = searchSupplierForm.controls['startDate'].value;
  //   let EndDate = searchSupplierForm.controls['endDate'].value;
  //   if(sendType != sendTypes.RangoPorFecha){
  //     StartDate = null;
  //     EndDate = null;
  //   }
  //   const SearchBPFromSap: any = {
  //     CompanyId: searchSupplierForm.controls['companyId'].value,
  //     StartDate: StartDate,
  //     EndDate: EndDate,
  //     Type: typeBp
  //   }
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `bearer ${TOKEN}`
  //   });
  //   return this.http.post<BusinessPartnerInfoResponse>(`${AppConstants.API_URL_INTEREST_RATE}api/BusinessPartner/GetBusinessPartnerFromSap`,
  //     SearchBPFromSap,
  //     { headers }
  //   );
  // }

  getSuppliersFromSap(searchSupplierForm: FormGroup, typeBp: string, sendType: string): Observable<BusinessPartnerInfoResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'responseType': 'blob'
    });

    let StartDate = searchSupplierForm.controls['startDate'].value;
    let EndDate = searchSupplierForm.controls['endDate'].value;
    if(sendType != sendTypes.RangoPorFecha){
      StartDate = null;
      EndDate = null;
    }
    const SearchBPFromSap: any = {
      CompanyId: searchSupplierForm.controls['companyId'].value,
      StartDate: StartDate,
      EndDate: EndDate,
      Type: typeBp
    }

    const url = `${AppConstants.API_URL}api/BusinessPartner/GetBusinessPartnerFromSap`;
    
    return this.http.post<BusinessPartnerInfoResponse>(url, SearchBPFromSap, { headers });
  }
  //#endregion PROVEEDORES

}
