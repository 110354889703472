import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseResponse, AppConstants } from '../common/models.index';
import { IUser, IRole } from '../models';
import { _isNumberValue } from '@angular/cdk/coercion';
import { IUsersResponse, IUserResponse, IPermissionsResponse } from '../models/responses';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  Store(_user: IUser): Observable<BaseResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${AppConstants.API_URL}api/User/Store`;
    
    return this.httpClient.post<BaseResponse>(url, _user, { headers });
  }

  Update(_user: IUser): Observable<BaseResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${AppConstants.API_URL}api/User/Put`;
    
    return this.httpClient.post<BaseResponse>(url, _user, { headers });
  }

  Get(): Observable<IUsersResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${AppConstants.API_URL}api/User/Get`;
    
    return this.httpClient.get<IUsersResponse>(url, { headers });
  }

  GetUser(_id: number): Observable<IUserResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${AppConstants.API_URL}api/User/GetUser?id=${_id}`;
    
    return this.httpClient.get<IUserResponse>(url, { headers });
  }

  GetRole(_userId: string): Observable<IPermissionsResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${AppConstants.API_URL}api/User/GetPermissions?userId=${_userId}`;
    
    return this.httpClient.get<IPermissionsResponse>(url, { headers });
  }

  PutByRole(_IdRole: number, _roles: IRole[]): Observable<BaseResponse> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const url = `${AppConstants.API_URL}api/Role/PutByUser`;
    
    return this.httpClient.post<BaseResponse>(url, {
      'IdUser': _IdRole,
      'Roles': _roles
    }, { headers });
  }

  FlushSession() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    
    const url = `${AppConstants.API_URL}api/User/FlushSession`;
    
    return this.httpClient.get<BaseResponse>(url, { headers });
  }
}
