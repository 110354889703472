import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from '../common/models.index';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  session: any;

  constructor(
    private http: HttpClient
    ,private authenticationService: AuthenticationService
    ,private router: Router) {
    this.session = { Company: 'Panaplast', UserName: '' };
  }

  anyActiveSession() {
    let session: any = localStorage.getItem('Session');
    if (!session) return false;

    session = JSON.parse(session);

    const sessionExpireDate = new Date(session.ExpireTime);

    return sessionExpireDate > new Date();
  }

  setCurrentSession(session: any) {
    this.session = {
      Company: 'Panaplast',
      UserName: session.Username,
      Token: session.access_token,
      UserId: session.UserId,
      Keys: session.Keys,
      ExpireTime: session.ExpireTime,
      Active: true,
    };

    localStorage.setItem('Session', JSON.stringify(this.session));
  }

  loadSessionFromLocalStorage() {
    this.session = JSON.parse(localStorage.getItem('Session'));
  }

  logIn(userId: string) {
    const body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', userId);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http.post<any>(
      `${AppConstants.API_URL}token`,
      body.toString(),
      {
        headers,
      }
    );
  }

  logOut() {
    this.session = { Company: 'ClavisCo', UserName: 'Not logged' };
    localStorage.removeItem('Session');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('thirdParty_token');
    localStorage.removeItem('Perms');
    this.authenticationService.deleteCurrentUserValue();
    this.router.navigate(['login']);
  }
}
