<div class="login-page" *ngIf="isLogin">
    <div class="form">
        <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">

            <img class="imgSize" src="assets/images/logoPanaplast.png">
            <br><br>
            <div class="form-group">
                <input type="text" #userMail formControlName="email" class="form-control no-margin form-control-sm" placeholder="Usuario" [ngClass]="{ 'is-invalid': fLogin.email.errors && fLogin.email.touched }" />
                <div *ngIf="fLogin.email.errors" class="invalid-feedback">
                    <div *ngIf="fLogin.email.errors.required">El usuario es requerido</div>
                </div>
            </div>

            <div class="form-group">
                <input type="password" formControlName="password" class="form-control no-margin form-control-sm" placeholder="Contraseña" [ngClass]="{ 'is-invalid': fLogin.password.errors && fLogin.password.touched }" />
                <div *ngIf="fLogin.password.errors" class="invalid-feedback">
                    <div *ngIf="fLogin.password.errors.required">La contraseña es requerida</div>
                </div>
            </div>

            <br>
            <div class="form-group">
                <button type="submit" [disabled]="loginForm.invalid || loading" class="btn btn-primary"><i class="fa fa-sign-in" aria-hidden="true"></i> Iniciar sesión</button>
                <!-- <p class="message">Bienvenido, recuperar la contraseña? <a class="cursorPointer" (click)="clickEvent(false)">Recuperar</a></p> -->
            </div>
        </form>
        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
    </div>
</div>

<div class="forgot-page" *ngIf="!isLogin">
    <div class="form">
        <form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
            <h3><img src="favicon.ico" /> GG </h3>
            <div class="form-group">
                <input type="text" formControlName="email" class="form-control form-control-sm" placeholder="Email" [ngClass]="{ 'is-invalid': fLogin.email.errors }" />
                <div *ngIf="fLogin.email.errors" class="invalid-feedback">
                    <div *ngIf="fLogin.email.errors.required">El usuario es requerido</div>
                </div>
            </div>

            <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary">Enviar Correo de Recuperación</button>
                <p class="message">Bienvenido, desea iniciar sesión? <a class="cursorPointer" (click)="clickEvent(true)">Login</a></p>
            </div>

        </form>
        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
    </div>
</div>
