import { Injectable } from '@angular/core';
// Angular common
import { HttpClient, HttpHeaders } from '../common/angular.index';
// Models
import {
  AppConstants,
  DocumentStatesResponse,
  DocumentState,
  BaseResponse,
} from '../common/models.index';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentStateService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  getDocumentStates() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });
    return this.http.get<DocumentStatesResponse>(
      `${AppConstants.API_URL}api/DocumentState/GetDocumentStates`,
      { headers }
    );
  }

  handlePostOrPutState(docState: DocumentState) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });

    if (docState.Id > 0)
      return this.http.put<BaseResponse>(
        `${AppConstants.API_URL}api/DocumentState/PutDocumentState`,
        docState,
        { headers }
      );
    else
      return this.http.post<BaseResponse>(
        `${AppConstants.API_URL}api/DocumentState/PostDocumentState`,
        docState,
        { headers }
      );
  }

  deleteState(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });
    return this.http.delete<BaseResponse>(
      `${AppConstants.API_URL}api/DocumentState/DeleteDocumentState?id=${id}`,
      { headers }
    );
  }
}
