import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// MODELOS
import {
  CompanyModelResponse, CompanyListResponse, CompanyModel, SapConnectionModel, SapConnResponse
} from './../../../../models/index'

// RUTAS
// COMPONENTES
// SERVICIOS
import { AlertService, ConfigParamsService } from './../../../../services/index'
import { CommonService } from 'src/app/common/services.index';
import { ALERTS } from 'src/app/common/models.index';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  public createCompanyForm: FormGroup;
  public updateCompanyForm: FormGroup;

  companyList: Array<CompanyModel>; // obj almacena la lista de companias activas
  companyInEdition: CompanyModel; // obj almacena la compañia seleccionada
  sapConnList: Array<SapConnectionModel>; // obj que almacena conexiones sap
  signature: File;
  customerReport: File;
  supplierReport : File; 
  companyReport : File;

  constructor(private fb: FormBuilder,
    private configParamsService: ConfigParamsService,
    private alertService: AlertService,
    private commonService: CommonService
  ) { }

  errorMessage: string; // variable para el mensaje en caso de error

  ngOnInit() {
    this.ngOnLoad();
  }

  ngOnLoad() {
    this.createCompanyForm = this.fb.group({
      dbCode: ['', Validators.required],
      companyName: ['', Validators.required],
      sapConnectionId: ['', Validators.required],
      active: ['', Validators.required],
      companyReportName: [''],
      businessPartnerReportName: [''],
      supplierReportName: [''],
      emailTo: ['', Validators.required],
      emailCc: [''],
      InternalEmailReport: [''],
      Signature: [""]
    });

    this.updateCompanyForm = this.fb.group({      
      dbCode: ['', Validators.required],
      companySelected: ['', Validators.required],
      companyName: ['', Validators.required],
      sapConnectionId: ['', Validators.required],
      active: ['', Validators.required],
      companyReportName: [''],
      businessPartnerReportName: [''],
      supplierReportName: [''],
      emailTo: ['', Validators.required],
      emailCc: [''],
      InternalEmailReport: [''],
      Signature: [""]
    });

    this.getSapConnList();
    this.getCompaniesList();
  }

  get formCreateCompany() {
    return this.createCompanyForm.controls;
  }
  get formUpdateCompany() {
    return this.updateCompanyForm.controls;
  }

  getCompaniesList() {
    this.blockUI.start('Buscando compañías');
    console.log('Buscando compañías');
    this.configParamsService.getCompanyList()
      .subscribe((data: CompanyListResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.companyList = data.CompanyList;
          this.blockUI.stop();          
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  getSapConnList() {
    this.blockUI.start('Buscando conexiones');
    console.log('Buscando conexiones');
    debugger;
    this.configParamsService.getSapConnList()
      .subscribe((data: SapConnResponse) => {        
        if (data.Result) {
          this.sapConnList = data.SapConnList;
          this.blockUI.stop();
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  createCompany() {
    this.blockUI.start('Creando la compañía.');
    console.log('Creando la compañía');
    this.configParamsService.createCompany(this.createCompanyForm, this.signature, this.customerReport, this.supplierReport, this.companyReport)
      .subscribe((data: CompanyModelResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.alertService.ShowBigCenteredAlert(`Compañía creada correctamente`, ALERTS.success);
          this.createCompanyForm.reset(); 
          this.getCompaniesList();         
          this.blockUI.stop();          
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning);
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  updateCompany() {
    this.blockUI.start('Acutalizando la compañía');
    console.log('Acutalizando la compañía');
    this.configParamsService.updateCompany(this.companyInEdition, this.updateCompanyForm, this.signature, this.customerReport, this.supplierReport, this.companyReport)
      .subscribe((data: CompanyModelResponse) => {
        console.log(data);
        console.log(data.Result);
        if (data.Result) {
          this.alertService.ShowBigCenteredAlert(`Compañía actualizada correctamente`, ALERTS.success);
          this.updateCompanyForm.reset(); 
          this.getCompaniesList();         
          this.blockUI.stop();          
        } else {
          this.blockUI.stop(); // Stop blocking
          this.alertService.ShowBigCenteredAlert(`${data.ErrorInfo.Message}`, ALERTS.warning); 
        }
      }, (error: any) => {
        console.log(error);
        this.blockUI.stop(); // Stop blocking
        this.alertService.ShowBigCenteredAlert(`${error}`, ALERTS.error); 
      });
  }

  companySelected($event) {
    console.log("CompanySelected");    
    this.companyInEdition = undefined;
    this.companyInEdition = this.companyList.find(company => company.Id == $event.target.value);
    if (this.companyInEdition) {
      this.PatchAlertData(this.companyInEdition);
    } else {
      this.updateCompanyForm.reset();
    }
  }

  PatchAlertData(company: CompanyModel){ 
    console.log("CompanyPatch");   
    this.updateCompanyForm.reset({
      dbCode: company.DbCode,
      companySelected: company.Id,
      companyName: company.CompanyName,
      sapConnectionId: company.SAPConnectionId,
      active: company.Active,
      companyReportName: company.ReportPathCompany,
      businessPartnerReportName: company.ReportPathBusinessPartner,
      supplierReportName : company.ReportPathSupplier,
      emailTo: company.EmailTo,
      emailCc: company.EmailCC,
      InternalEmailReport: company.InternalEmailReport,
      Signature: company.SignatureFilePath
    })
  }

  onFileSelected($event: any, onPage: number, fileType: number, reportType: number) {    
    debugger;
    // onPage = 1 -> Vista actualizacion
    // fileType = 1 -> Signature, 2-> BpReport
    // reportType = 1 -> CustomerReport, 2 -> SupplierReport, 3 -> CompanyReport
    
    let theFile : File;
    theFile = <File>$event.target.files[0];

    if (!theFile) return;
    if (!this.commonService.isValidFile(theFile, ["png","jpeg","jpg","rpt"])) {      
      this.alertService.ShowBigCenteredAlert(`Archivo no soportado`, ALERTS.error);        
      return;
    }

    if(fileType == 1){
      this.signature = theFile;
    } 
    else{
      switch(reportType) { 
        case 1: { 
          this.customerReport = theFile;
           break; 
        } 
        case 2: { 
          this.supplierReport = theFile; 
           break; 
        } 
        case 3: { 
          this.companyReport = theFile; 
          break; 
       }        
     }      
    }
      
    if(onPage == 1){ // 1 -> Vista actualizacion
      if(fileType == 1){
        this.updateCompanyForm.get("Signature")?.setValue(this.signature.name);        
      }
      else{
        // reportType = 1 -> CustomerReport, 2 -> SupplierReport, 3 -> CompanyReport
        switch(reportType) { 
          case 1: { 
            this.updateCompanyForm.get("businessPartnerReportName")?.setValue(this.customerReport.name);
             break; 
          } 
          case 2: { 
            this.updateCompanyForm.get("supplierReportName")?.setValue(this.supplierReport.name); 
             break; 
          } 
          case 3: { 
            this.updateCompanyForm.get("companyReportName")?.setValue(this.companyReport.name); 
            break; 
         }        
       }         
      }      
    }
    else{
      // fileType = 1 -> Signature, 2-> BpReport
      if(fileType == 1){
        this.createCompanyForm.get("Signature")?.setValue(this.signature.name);        
      }
      else{
        // reportType = 1 -> CustomerReport, 2 -> SupplierReport, 3 -> CompanyReport
        switch(reportType) { 
          case 1: { 
            this.createCompanyForm.get("businessPartnerReportName")?.setValue(this.customerReport.name);
             break; 
          } 
          case 2: { 
            this.createCompanyForm.get("supplierReportName")?.setValue(this.supplierReport.name); 
             break; 
          } 
          case 3: { 
            this.createCompanyForm.get("companyReportName")?.setValue(this.companyReport.name); 
            break; 
         }        
       }         
      }
    }    
  }

  private stringToArrayBuffer(toConvert: string) {
    let buf = new ArrayBuffer(toConvert.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != toConvert.length; ++i)
      view[i] = toConvert.charCodeAt(i) & 0xff;
    return buf;
  }

  fn_downloadFile(ReportType: number) {
    // ReportType = 1 -> CustomerReport, 2 -> SupplierReport, 3 -> CompanyReport
    let ReportName = '';
    switch(ReportType) { 
      case 1: { 
        ReportName = this.updateCompanyForm.get('businessPartnerReportName')?.value;
         break; 
      } 
      case 2: { 
        ReportName = this.updateCompanyForm.get('supplierReportName')?.value;
         break; 
      } 
      case 3: { 
        ReportName = this.updateCompanyForm.get('companyReportName')?.value;
        break; 
     }        
   }    
    this.configParamsService.downloadReportFile(ReportName).subscribe(resp => {
      if (resp.Result) {
        debugger;
        this.downloadFile(resp.File, ReportName.split('.')[0], 'application/octet-stream', 'rpt');
      } else {
        this.alertService.ShowSmallCornerAlert(`Error al descargar archivo.${resp.ErrorInfo.Message}`, ALERTS.error);
      }
    }, error => {
      //this.blockUiService.Stop();
      this.alertService.ShowSmallCornerAlert(`Error: ${error}`, ALERTS.error);
    });
    // }else{
    //   this.alertService.ShowSmallCornerAlert(`No se ha configurado reporte para la descripción`, ALERTS.info);
    // }
  }  

  private downloadFile(
    base64File: string,
    fileName: string,
    blobType: string,
    fileExtension: string
  ) {
    let report = new Blob([this.stringToArrayBuffer(atob(base64File))], {
      type: blobType,
    }); 

    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(report);
    link.download = `${fileName}.${fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
