import { Injectable } from '@angular/core';
// Angular common
import { HttpClient, HttpHeaders } from '../common/angular.index';

// Models
import {
  AppConstants,
  RequirementsResponse,
  Requirement,
  BaseResponse,
} from '../common/models.index';
import { SessionService } from './session.service';
@Injectable({
  providedIn: 'root',
})
export class RequirementsService {
  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {}

  getRequirements(onlyParentRequirements: boolean) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });

    return this.http.get<RequirementsResponse>(
      `${AppConstants.API_URL}api/Requirement/GetRequirements?onlyParentRequirements=${onlyParentRequirements}`,
      { headers }
    );
  }

  handlePostOrPutRequirement(requirement: Requirement) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });
    if (requirement.Id > 0)
      return this.http.put<BaseResponse>(
        `${AppConstants.API_URL}api/Requirement/PutRequirement`,
        requirement,
        { headers }
      );
    else
      return this.http.post<BaseResponse>(
        `${AppConstants.API_URL}api/Requirement/PostRequirement`,
        requirement,
        { headers }
      );
  }

  deleteRequirement(id: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });

    return this.http.delete<BaseResponse>(
      `${AppConstants.API_URL}api/Requirement/DeleteRequirement?requirementId=${id}`,
      { headers }
    );
  }

  getRequirementSons(requirementId: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.sessionService.session.Token}`,
    });

    return this.http.get<RequirementsResponse>(
      `${AppConstants.API_URL}api/Requirement/GetRequirementSons?requirementId=${requirementId}`,
      { headers }
    );
  }
}
