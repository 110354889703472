import { Injectable } from '@angular/core';
import { ISearch } from '../models/i-search';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IBinnacleResponse } from '../models/responses';
import { AppConstants } from '../common/constants';

@Injectable({
  providedIn: 'root'
})
export class BinnacleService {

  constructor(private httpClient: HttpClient) { }

  GetBinnacle(_search: ISearch): Observable<IBinnacleResponse> {
    // const token = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Authorization': `Bearer ${token.access_token}`
    });

    const url = `${AppConstants.API_URL}api/Binnacle/GetBinnacle`;
    
    return this.httpClient.post<IBinnacleResponse>(url, _search, { headers });
  }
}
